<template>
  <section
    class="
      relative
      sm:py-8 sm:px-8 sm:bg-white sm:mx-auto sm:w-full
      rounded-xl
      shadow-lg
      py-5
      px-5
    "
  >
    <h3 class="font-bold sm:text-2xl text-lg text-center">
      Productividad por canal
    </h3>
    <h5 class="sm:text-sm text-sm text-center">
      Se visualizaran los primeros 10 con mayor productividad
    </h5>
    <div class="grid grid-cols-2 gap-4">
      <BaseMultiSelect
        title="Canales"
        :data="dataSelect"
        label="canal"
        @notify-select="setMultiSelect($event)"
      />
    </div>
    <apexchart
      v-if="!progress"
      type="bar"
      height="100%"
      :options="chartOptions"
      :series="series"
    ></apexchart>
    <div v-if="progress" class="flex justify-center items-center">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 36px" spin />
      </a-spin>
    </div>
  </section>
</template>
<script>
export default {
  // Datos de para el eje de las ordenadas y el eje de las axisas
  props: {
    data: [],
    dataSelect: [],
    horizontal: {
      type: Boolean,
      default: false,
    },
    distributed: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    "$props.data": function () {
      this.transformData();
    },
  },
  data() {
    return {
      series: [{ data: [] }],
      chartOptions: {
        chart: {
          type: "bar",
          
        },
         noData: {
          text: 'No hay datos para graficar',
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "24px",
            fontFamily: undefined,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
            distributed: false,
            borderRadius: 10,
            horizontal: undefined,
            dataLabels: {
              position: "top",
              hideOverflowingLabels: true,
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
        responsive: [
          {
            breakpoint: 900,
            options: {
              xaxis: {
                labels: {
                  style: {
                    fontSize: "7px",
                  },
                },
              },
              plotOptions: {
                bar: {
                  borderRadius: 6,
                },
              },
            },
          },
          {
            breakpoint: 300,
            options: {
              chart: {
                height: "100%",
                width: "100%",
                zoom: {
                  enabled: false,
                },
              },
              plotOptions: {
                bar: {
                  borderRadius: 2,
                },
              },
              xaxis: {
                labels: {
                  style: {
                    fontSize: "7px",
                  },
                },
              },
              yaxis: [
                {
                  title: {
                    text: "",
                  },
                },
                {
                  opposite: true,
                  title: {
                    text: "",
                  },
                },
              ],
            },
          },
        ],

        theme: {
          monochrome: {
            enabled: true,
            color: "#3366cc",
            shadeTo: "dark",
            shadeIntensity: 0.8,
          },
        },
      },
    };
  },
  created() {
    this.transformData();
    this.chartOptions.plotOptions.bar.distributed = this.distributed;
    this.chartOptions.plotOptions.bar.horizontal = this.horizontal;
    this.chartOptions.dataLabels.offsetX = this.horizontal ? -14 : 0;
    this.chartOptions.dataLabels.offsetY = !this.horizontal ? 14 : 0;
  },
  methods: {
    setMultiSelect(ev) {
      this.$emit("notify-filters", ev);
    },
    transformData() {
      this.chartOptions.xaxis.categories = this.$props.data?.map((el) =>
        el.canal + ''
      );
      this.series[0].data = this.$props.data?.map(
        (el) => +(el.productividad * 100).toFixed(2)
      );
    },
  },
  computed: {
    noData() {
      console.table(this.chartOptions.xaxis.categories);
      return true;
    },
  },
};
</script>
