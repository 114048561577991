<template>
  <div class="w-full mt-5 p-6 bg-white rounded-xl shadow-lg mb-4">
    <div class="flex gap-2">
      <a-icon
        type="filter"
        class="mt-1"
        :style="{
          fontSize: '20px',
          color: 'gray',
        }"
      ></a-icon>
      <h2 class="text-left text-lg text-gray-600 font-bold">
        Filtrar por rango de fechas
      </h2>
    </div>
    <div class="flex flex-col gap-2">
      <div class="w-full grid grid-cols-2 lg:grid-cols-4 gap-4">
        <div class="h-full w-full mt-5 text-left flex flex-col">
          <label class="mb-2 text-xs md:text-xs text-gray-500 text-opacity-80"
            >Período</label
          >
          <a-range-picker
            :value="[start_date, end_date]"
            @change="onChange"
            :placeholder="['Fecha de inicio', 'Fecha de fin']"
          />
        </div>
        <a-button
          @click="applyFilters"
          type="primary"
          shape="round"
          class="w-full h-10 mt-auto font-thin text-sm"
        >
          <a-icon type="sliders" />
          Aplicar filtro
        </a-button>
      </div>
    </div>
  </div>
</template>
<script>
import SearchFilter from "@/components/Dashboard/Management/SearchFilter";
import moment from "moment";

export default {
  components: {
    SearchFilter,
  },
  data() {
    return {
      columns: [],
      start_date: null,
      end_date: null,
      rangeDates: {
        to: null,
        from: null,
      },
      oldRange: { to: null, from: null },
    };
  },
  created() {
    this.start_date = moment().set(new Date()).subtract(7, "d");
    this.end_date = moment().set(new Date());
  },
  methods: {
    applyFilters() {
      if (
        this.rangeDates.to != this.oldRange?.to ||
        this.rangeDates.from != this.oldRange?.from
      ) {
        this.$emit("notify-dates", this.rangeDates);
        this.oldRange = { ...this.rangeDates };
      }
    },
    onChange(date, dateString) {
      this.start_date = date[0];
      this.end_date = date[1];
      this.rangeDates.to = dateString[1];
      this.rangeDates.from = dateString[0];
    },
  },
};
</script>