<template>
  <div>
    <FilterDates @notify-dates="generalFilter" />

    <div
      v-if="!loading"
     class="
        grid
        sm:grid-cols-2
        md:grid-cols-2
        lg:grid-cols-4
        3xl:grid-cols-8
        md:gap-x-5
        gap-1
        justify-around
      "
    >
      <ComplaintCardMini
        v-for="complaint in complaints"
        :key="complaint.title"
        :title="complaint.title"
        :numComplaints="complaint.numComplaints"
        :percent="complaint.percent"
        :danger="complaint.danger"
        :fall="complaint.fall"
      />
    </div>

    <div v-if="!loading" class="productivity">
      <ProductivityCompany
        :data="dataCompany"
        :dataSelect="dataSelectCompany"
        @notify-filters="applyFilter($event, 'entidades')"
        class="company"
      />

      <ProductivityChannel
        title="Productividad por Canal"
        horizontal
        :data="dataChannel"
        :dataSelect="dataSelectChannel"
        :progress="progressChannel"
        @notify-filters="applyFilter($event, 'canales')"
        class="channel"
      />

      <ProductivityProduct
        title="Productividad por Producto"
        horizontal
        :data="dataProduct"
        :dataSelect="dataSelectProduct"
        :progress="progressProduct"
        @notify-filters="applyFilter($event, 'productos')"
        class="product"
      />
      <ProductivityReason
        :data="dataReason"
        :dataSelect="dataSelectReason"
        @notify-filters="applyFilter($event, 'motivos')"
        class="reason"
      />
    </div>

     <div v-if="loading" class="flex justify-center items-center mt-32">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
  </div>
</template>
<script>
import ProductivityCompany from "@/components/Dashboard/Management/Productivity/ProductivityCompany.vue";
import ProductivityChannel from "@/components/Dashboard/Management/Productivity/ProductivityChannel.vue";
import ProductivityProduct from "@/components/Dashboard/Management/Productivity/ProductivityProduct.vue";
import ProductivityReason from "@/components/Dashboard/Management/Productivity/ProductivityReason.vue";
import ComplaintCardMini from "@/components/Dashboard/Management/General/ComplaintCardMini";
import FilterDates from "@/components/Dashboard/Management/FilterDates";
import moment from "moment";

const arrVolumetry = [
  {
    name: "Total de quejas",
    target: "total_codigo_queja",
    percent: "porcentaje_codigo_queja",
  },
  {
    name: "Quejas con Tutela",
    target: "total_tutela",
    percent: "porcentaje_codigo_queja",
  },
  {
    name: "Total Entidades",
    target: "total_nombre_entidad",
    percent: "porcentaje_codigo_queja",
  },
  {
    name: "Total Usuarios",
    target: "total_numero_id_CF",
    percent: "porcentaje_codigo_queja",
  },
  {
    name: "Total Ciudades",
    target: "total_codigo_pais",
    percent: "porcentaje_codigo_queja",
  },
  {
    name: "Tiempo total de respuesta",
    target: "sum_secconds",
    percent: "porcentaje_sum_secconds",
  },
  {
    name: "Cantidad de prorrogas",
    target: "total_sum_prorroga_queja",
    percent: "porcentaje_prorroga_queja",
  },
  {
    name: "Tiempo promedio de respuesta",
    target: "avg_secconds",
    percent: "porcentaje_avg_secconds",
  },
];
export default {
  components: {
    ComplaintCardMini,
    ProductivityCompany,
    ProductivityChannel,
    ProductivityProduct,
    ProductivityReason,
    FilterDates,
  },
  created() {
    this.start_date = moment()
      .set(new Date())
      .subtract(7, "d")
      .format("YYYY-MM-DD");
    this.end_date = moment().set(new Date()).format("YYYY-MM-DD");
    this.getListSelect();
  },
  data() {
    return {
      loading: false,
      progressChannel: false,
      progressProduct: false,
      start_date: null,
      end_date: null,
      complaints: [],
      dataCompany: [],
      dataChannel: [],
      dataProduct: [],
      dataReason: [],
      dataSelectCompany: [],
      dataSelectChannel: [],
      dataSelectProduct: [],
      dataSelectReason: [],
    };
  },
  methods: {
    getListSelect() {
      this.showToast("info", "Consultando gráficas...");
      this.loading = true;
      const promises = [];
      // 1. Entidades
      promises.push(this.$api.getDataForFilter("nombre_entidad"));
      // 2. Canales
      promises.push(this.$api.getDataForFilter("canal"));
      // 3. Productos
      promises.push(this.$api.getDataForFilter("producto_nombre"));
      // 4. Motivos
      promises.push(this.$api.getDataForFilter("macro_motivo"));
      // Temporal
      const bodyVolumetry = {
        filter_model: {
          fecha_creacion: {
            filter_type: "date",
            operator: "AND",
            condition1: {
              date_from: this.start_date,
              date_to: null,
              filter_type: "date",
              type: "greaterThanOrEqual",
            },
            condition2: {
              date_from: this.end_date,
              date_to: null,
              filter_type: "date",
              type: "lessThanOrEqual",
            },
          },
        },
        limit: 10,
        offset: 0,
      };
      promises.push(this.$api.getVolumetry(bodyVolumetry));

      // Ejecutamos todas las peticiones al backend
      Promise.allSettled(promises)
        .then((response) => {
          this.dataSelectCompany = response[0].value?.data?.filter(
            (el) => el.nombre_entidad
          );
          this.dataSelectChannel = response[1].value?.data?.filter(
            (el) => el.canal
          );
          this.dataSelectProduct = response[2].value?.data?.filter(
            (el) => el.producto_nombre
          );
          this.dataSelectReason = response[3].value?.data?.filter(
            (el) => el.macro_motivo
          );
          const volumetry = response[4]?.value?.data;
          this.setVolumetry(volumetry);
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
        });
    },
    setVolumetry(volumetry) {
      this.complaints = [];
      let factor = 1;
      arrVolumetry.forEach((item) => {
        factor = item.target.includes("secconds") ? 3600 : 1;

        if (volumetry[item.percent] === null) {
          this.complaints.push({
            title: item.name,
            percent: 0,
            numComplaints: 0,
            danger: false,
            fall: false,
          });
        } else {
          this.complaints.push({
            title: item.name,
            percent: +(
              volumetry[item.percent] < 0
                ? -1 * volumetry[item.percent]
                : volumetry[item.percent]
            ).toFixed(2),
            numComplaints: volumetry[item.target] / factor,
            danger: volumetry[item.percent] < 0,
            fall: volumetry[item.percent] < 0,
          });
        }
      });
    },
    applyFilter(ev, name_filter) {
      const bodyProductivity = {
        filter_model: this.getFilterModel(name_filter, ev),
        limit: 10,
        offset: 0,
      };
      if (name_filter === "canales") {
        this.progressChannel = true;
      } else if (name_filter === "productos") {
        this.progressProduct = true;
      }
      // this.showToast("info", "Consultando " + name_filter);
      this.$api
        .getProductivity(name_filter, bodyProductivity)
        .then((res) => {
          switch (name_filter) {
            case "entidades":
              this.dataCompany = res?.data?.results.map((el) => {
                return {
                  ...el,
                  nombre_entidad: el.nombre_entidad + "",
                  productividad: +(el.productividad * 100).toFixed(2),
                };
              });
              break;
            case "canales":
              this.dataChannel = res?.data?.results;
              this.progressChannel = false;
              break;

            case "productos":
              this.dataProduct = res?.data?.results;
              this.progressProduct = false;
              break;

            case "motivos":
              this.dataReason = res?.data?.results.map((el) => {
                return {
                  ...el,
                  productividad: +(el.productividad * 100).toFixed(2),
                };
              });
              break;

            default:
              break;
          }
        })
        .catch((e) => console.error(e));
    },
    generalFilter(ev) {
      this.start_date = ev.from;
      this.end_date = ev.to;
      this.showToast("info", "Consultando gráficas...");
      this.loading = true;
      const promises = [];

      const bodyProductivity = {
        filter_model: {
          fecha_creacion: {
            filter_type: "date",
            operator: "AND",
            condition1: {
              date_from: this.start_date,
              date_to: null,
              filter_type: "date",
              type: "greaterThanOrEqual",
            },
            condition2: {
              date_from: this.end_date,
              date_to: null,
              filter_type: "date",
              type: "lessThanOrEqual",
            },
          },
        },
        limit: 10,
        offset: 0,
      };
      // 1. Entidades
      promises.push(this.$api.getProductivity("entidades", bodyProductivity));
      // 2. Canales
      promises.push(this.$api.getProductivity("canales", bodyProductivity));
      // 3. Productos
      promises.push(this.$api.getProductivity("productos", bodyProductivity));
      // 4. Motivos
      promises.push(this.$api.getProductivity("motivos", bodyProductivity));
      // 5. Volumetría
      promises.push(this.$api.getVolumetry(bodyProductivity));

      // Ejecutamos todas las peticiones al backend
      Promise.allSettled(promises)
        .then((response) => {
          this.dataCompany = response[0].value?.data?.results?.map((el) => {
            return {
              ...el,
              nombre_entidad: el.nombre_entidad + "",
              productividad: +(el.productividad * 100).toFixed(2),
            };
          });
          this.dataChannel = response[1].value?.data?.results;
          this.dataProduct = response[2].value?.data?.results;
          this.dataReason = response[3].value?.data?.results?.map((el) => {
            return {
              ...el,
              productividad: +(el.productividad * 100).toFixed(2),
            };
          });
          const volumetry = response[4]?.value?.data;
          this.setVolumetry(volumetry);
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
        });
    },
    getFilterModel(name_att, list_filter) {
      let filter = {
        fecha_creacion: {
          filter_type: "date",
          operator: "AND",
          condition1: {
            date_from: this.start_date,
            date_to: null,
            filter_type: "date",
            type: "greaterThanOrEqual",
          },
          condition2: {
            date_from: this.end_date,
            date_to: null,
            filter_type: "date",
            type: "lessThanOrEqual",
          },
        },
      };
      switch (name_att) {
        case "entidades":
          if (list_filter.length !== this.dataSelectCompany.length) {
            filter["nombre_entidad"] = {
              filter_type: "set",
              values: list_filter,
            };
          }
          break;
        case "canales":
          if (list_filter.length !== this.dataSelectChannel.length) {
            filter["canal"] = {
              filter_type: "set",
              values: list_filter,
            };
          }
          break;
        case "productos":
          if (list_filter.length !== this.dataSelectProduct.length) {
            filter["nombre_producto"] = {
              filter_type: "set",
              values: list_filter,
            };
          }
          break;
        case "motivos":
          if (list_filter.length !== this.dataSelectReason.length) {
            filter["macro_motivo"] = {
              filter_type: "set",
              values: list_filter,
            };
          }
          break;

        default:
          break;
      }

      return filter;
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
};
</script>
<style scoped>
.productivity {
  box-sizing: border-box;
  display: grid;
  height: auto;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content min-content;
  grid-template-areas:
    "by-entity by-channel"
    "by-product by-reason";
  gap: 1em;
}
.company {
  grid-area: by-entity;
}
.channel {
  grid-area: by-channel;
}
.product {
  grid-area: by-product;
}
.reason {
  grid-area: by-reason;
}
@media screen and (max-width: 1024px) {
  .productivity {
    height: auto;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, max-content);
    grid-template-areas:
      "by-entity"
      "by-channel"
      "by-product "
      "by-reason";
  }
}
</style>