<template>
  <div class="p-6 bg-white rounded-xl shadow-lg mb-4">
    <div class="flex">
      <img src="@/assets/icons/search-grey.svg" alt="icono de busqueda gris" class="h-3 my-auto mr-2" />
      <h2 class="text-left text-md text-gray-600">
        <b>Filtros de búsqueda</b>
      </h2>
    </div>
    <div v-if="!loading && filterSet" class="flex flex-col gap-2">
      <div class="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-5 gap-4">
        <button
          @click="onSearch"
          class="
            rounded-md
            w-full
            h-10
            mt-auto
            hover:bg-blue-600
            bg-blue-700
            text-white
          "
        >
          <a-icon type="sliders" />
          {{ showModal ? "Ocultar" : "Desplegar" }} filtros
        </button>
        <BaseRangeDate @notify-dates="generalFilter" />
      </div>
      <div
        v-if="showModal"
        class="
          w-11/12 w-full
          grid grid-cols-1
          md:grid-cols-2
          xl:grid-cols-5
          gap-4
        "
      >
        <div v-for="item in columns" :key="item.name" class="filter-inputs">
          <BaseInput
            v-if="item.type === 'TEXT'"
            :label="item.description"
            v-model="filterSet[item.name].data"
          />
          <BaseDate
            v-if="item.type === 'DATETIME'"
            :label="item.description"
            v-model="filterSet[item.name].data"
          />
          <BaseNumber
            v-if="item.type === 'NUMBER'"
            :label="item.description"
            v-model="filterSet[item.name].data"
          />
          <BaseMultiSelect
            v-if="
              item.type === 'SELECT' &&
              ((relColombia.includes(item.name) && flagColombia) ||
                !relColombia.includes(item.name))
            "
            :title="item.description"
            :data="item.data"
            :label="item.name"
            @notify-select="setMultiSelect($event, item.name)"
          />
        </div>
      </div>
      <div
        class="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-5 gap-4 mt-5"
      >
        <button
          v-if="showModal"
          @click="applyFilters"
          class="
            rounded-md
            w-1/2
            h-10
            mt-auto
            hover:bg-blue-600
            bg-blue-700
            text-white
          "
        >
          <a-icon type="sliders" />
          Aplicar filtros
        </button>
      </div>
    </div>
    <div v-if="loading" class="flex justify-center items-center">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 36px" spin />
      </a-spin>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["loading", "columns"],
  data() {
    return {
      filters: [],
      showModal: false,
      filterSet: null,
      rangeDates: {},
      flagColombia: false,
      relColombia: ["nombre_departamento", "nombre_municipio"],
    };
  },
  created() {
    // Construimos el objeto que tendrá la configuración de los filtros
    this.rangeDates = {
      date_from: moment().set(new Date()).subtract(7, "d").format("YYYY-MM-DD"),
      date_to: moment().set(new Date()).format("YYYY-MM-DD"),
    };
    this.filterSet = {};
    this.$props.columns.forEach((el) => {
      this.filterSet[el.name] = {
        type: el.type,
        data: el.type === "SELECT" ? el.data : null,
      };
    });
  },
  methods: {
    onSearch() {
      this.showModal = !this.showModal;
    },
    generalFilter(ev) {
      this.rangeDates = {
        date_from: ev[0],
        date_to: ev[1],
      };
      if (!this.showModal) this.applyFilters();
    },
    applyFilters() {
      let resultFilter = {};
      // Obtenemos todas las columnas y las convertimos en formato para el backend
      const nameColumns = Object.keys(this.filterSet);
      nameColumns.forEach((item) => {
        resultFilter = {
          ...resultFilter,
          ...this.convertTypeFilter(
            item,
            this.filterSet[item].type,
            this.filterSet[item].data
          ),
        };
      });

      // Se agrega el rango de fechas para la consulta
      resultFilter["fecha_creacion"] = {
        filter_type: "date",
        operator: "AND",
        condition1: {
          date_from: this.rangeDates.date_from,
          date_to: null,
          filter_type: "date",
          type: "greaterThanOrEqual",
        },
        condition2: {
          date_from: this.rangeDates.date_to,
          date_to: null,
          filter_type: "date",
          type: "lessThanOrEqual",
        },
      };
      this.$emit("notify-filters", {
        filter_model: resultFilter,
      });
    },
    setMultiSelect(ev, name) {
      this.filterSet[name].data = ev;
      if (name === "nombre_pais") {
        this.flagColombia = this.filterSet["nombre_pais"]?.data?.includes(
          "COLOMBIA"
        )
          ? true
          : false;
      }
    },
    convertTypeFilter(name_att, filter_type, data) {
      let resJSON = {};
      // Convertir un campo en su valor conocido por el backend
      if (
        filter_type === "SELECT" &&
        data?.length !==
          this.$props.columns?.find((el) => el.name == name_att)?.data.length
      ) {
        // Extra condition: If nombre_pais not contains Colombia then not to add nombre_municipio and nombre_departamento
        if (
          (this.relColombia.includes(name_att) && this.flagColombia) ||
          !this.relColombia.includes(name_att)
        ) {
          resJSON[name_att] = {
            filter_type: "set",
            values: data,
          };
        }
      } else if (filter_type === "TEXT") {
        if (data === null || data === "") return null;
        resJSON[name_att] = {
          filter_type: "text",
          type: "contains",
          filter: data,
        };
      } else if (filter_type === "NUMBER") {
        if (data === null || data === "") return null;
        resJSON[name_att] = {
          filter_type: "number",
          type: "equals",
          filter: data,
          filterTo: null,
        };
      } else if (filter_type === "DATETIME") {
        if (data === null || data === "") return null;
        resJSON[name_att] = {
          filter_type: "date",
          type: "greaterThanOrEqual",
          date_from: data,
          date_to: null,
        };
      }
      return resJSON;
    },
  },
};
</script>