<template>
  <div
    class="
      sm:p-2
      min-w-full
      mx-auto
      bg-white
      rounded-xl
      shadow-lg
      space-y-1
      sm:my-3
      mb-2
      box-border
      sm:flex sm:flex-cols-2 gap-3 sm:items-center 
      p-2
      3xl:py-0 2xl:justify-center
      2xl:space-x-6
    "
  >
    <img
      class="
        sm:block
        mx-auto
        sm:h-20
        3xl:h-14
        h-14
        rounded-full
        sm:mx-0 sm:shrink-0
        chart-img
      "
      src="@/assets/icons/charts.svg"
      alt="Charts"
    />
    <div class="text-center space-y-1 sm:text-left py-2 ">
      <div class="space-y-0.5">
        <p class="sm:text-sm lg:text-md font-semibold text-gray-400 text-2xl 3xl:text-xs">{{ title }}</p>

        <p class="text-black  sm:text-xl text-xl 3xl:text-sm">
          {{ numComplaints | number("0,0") }}
        </p>
      </div>
      <div
        class="
          sm:text-md
          text-red
          font-light
          rounded-full
          focus:outline-none focus:ring-2 focus:ring-offset-2
          text-md
          lg:text-lg
        "
        :class="percentClass"
      >
        <a-icon :type="fall ? 'fall' : 'rise'" :rotate="fall ? 90 : 0" />
        {{ percent }}&#37;
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    //titulo de la tarjeta
    title: {
      type: String,
      default: "Complaint card title",
      require: true,
    },
    // total de quejas
    numComplaints: {
      type: Number,
      default: 0,
      require: true,
    },
    //porcentaje
    percent: {
      type: Number,
      default: 0,
      required: true,
    },
    // TO_DO: consultar bajo que criterios es rojo el icono y la orientacición de la flecha
    danger: {
      type: Boolean,
      default: false,
    },
    fall: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },

  computed: {
    percentClass: function () {
      return this.danger ? "text-red-500" : "text-green-400";
    },
  },
};
</script>