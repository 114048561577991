<template>
  <div
    class="
      relative
      sm:py-8 sm:px-8 sm:bg-white sm:mx-auto sm:w-full
      rounded-xl
      shadow-lg
      py-3
      px-3
    "
  >
    <h3 class="font-bold sm:text-2xl text-lg text-center">
      Productividad por entidad
    </h3>
    <h5 class="sm:text-sm text-sm text-center">
        Se visualizaran las entidades seleccionadas con su productividad
    </h5>
    <div class="grid grid-cols-2 gap-4">
    <BaseMultiSelect
            title="Entidades"
            :data="dataSelect"
            label="nombre_entidad"
            @notify-select="setMultiSelect($event)"
          />
    </div>
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="false"
      class="sm:mt-5"
      rowKey="nombre_entidad"
    >
    <span
        slot="productivity"
        slot-scope="text, record"
        class="text-gray-400 sm:text-sm text-left sm:my-1"
      >
        <a-progress
          :percent="record.productividad"
          :strokeWidth="9"
          strokeColor="blue"
          strokeLinecap="square"
        />
      </span>
    </a-table>
  </div>
</template>
<script>

const columns = [
  {
    title: "Nombre entidad",
    dataIndex: "nombre_entidad",
    key: "nombre_entidad",
  },
  {
    title: "Productividad",
    dataIndex: "productividad",
    scopedSlots: { customRender: "productivity" },
  },
];

export default {
  props: {
      data: [],
      dataSelect: []
  },

  data() {
    return {
        columns
    };
  },
  methods: {
      setMultiSelect(ev) {
          this.$emit('notify-filters', ev);

      }
  }
};
</script>
<style scoped>
th {
  background-color: gray;
}
</style>