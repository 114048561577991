import { render, staticRenderFns } from "./Productivity.vue?vue&type=template&id=191b1a45&scoped=true"
import script from "./Productivity.vue?vue&type=script&lang=js"
export * from "./Productivity.vue?vue&type=script&lang=js"
import style0 from "./Productivity.vue?vue&type=style&index=0&id=191b1a45&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "191b1a45",
  null
  
)

export default component.exports